import React, {Fragment} from 'react';
import s from './style/guide.module.scss'
import request from '../utils/request';
import {toast} from 'react-toastify';
import '../components/loadingmask/index.css'
import imgPoster from '../assets/mobile/huanguo.jpg';
import imgChecked from '../assets/mobile/checked.png';
import imgNoChecked from '../assets/mobile/not-checked.png';
import {Button, Col, Input, Radio, Row, message} from 'antd';
import 'antd/dist/antd.css';
import LoadingMask from '../components/loadingmask';
import consts from '../utils/consts'
import {copyToClipboard} from '../utils/utils';
class MGuide extends React.PureComponent{
  state = {
    submitting: false,
    oldPotDeliverNo: '',
    newPotDeliverAddress: '',
    newPotDeliverReceiverName: localStorage.getItem('newPotDeliverReceiverName') || '',
    newPotDeliverReceiverPhone: localStorage.getItem('newPotDeliverReceiverPhone') || '',
    userMemo: '',
    newPotDeliverShopName: '',
    cargoFetch: true,
    cargoFetchCompanyCode: 'shunfeng',
    currentCookCount: null,
    recentProcessingRequest: null,
  };
  
  componentDidMount() {
    const { machine = {} } = this.props;
    let { newPotDeliverAddress, newPotDeliverShopName } = this.state;
    if (newPotDeliverAddress === '' && machine.shop) {
      newPotDeliverAddress =  machine.shop.address.trim();
    }
    if (machine.shop) {
      newPotDeliverShopName = `${machine.shop.brandName}-${machine.shop.branchName}-${machine.shop.name}`;
    }
    this.setState({
      newPotDeliverAddress,
      newPotDeliverShopName,
    });
    const { recentRequest } = machine;
    const cookCount = localStorage.getItem('cookCount') || 0;
    let currentCookCount = cookCount;
    //根据最近的换锅记录，计算统计差值
    if (recentRequest) {
      const prevCookCount = recentRequest.cookCount;
      if (cookCount >= prevCookCount) {
        currentCookCount = cookCount - prevCookCount;
      }
      this.setState({
        currentCookCount,
        cargoFetchCompanyCode: recentRequest.kuaidiOldPotCompanyCode,
      });
      
      //如果最近的换锅记录是正在进行中（非新锅已发出状态），则标记
      if (recentRequest.status !== consts.potChangeRequestStatus.NewPotDelivered) {
        this.setState({
          cargoFetch: recentRequest.kuaidiOldPotTaskId !== '',
          cargoFetchCompanyCode: recentRequest.kuaidiOldPotCompanyCode,
          oldPotDeliverNo: recentRequest.oldPotDeliverNo,
          newPotDeliverAddress: recentRequest.newPotDeliverAddress,
          newPotDeliverReceiverName: recentRequest.newPotDeliverReceiverName,
          newPotDeliverReceiverPhone: recentRequest.newPotDeliverReceiverPhone,
          userMemo: recentRequest.userMemo,
          recentProcessingRequest: recentRequest,
        });
      }
    } else {
      this.setState({
        currentCookCount,
      });
    }
  }
  
  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    const {
      oldPotDeliverNo,
      newPotDeliverAddress,
      newPotDeliverReceiverName,
      newPotDeliverReceiverPhone,
      newPotDeliverShopName,
      userMemo,
      cargoFetch,
      cargoFetchCompanyCode,
      recentProcessingRequest
    } = this.state;
    /*if (!cargoFetch && oldPotDeliverNo.trim() === '') {
      toast.warn('旧锅快递单号不能为空');
      return;
    }*/
    if (newPotDeliverAddress.trim() === '') {
      toast.warn('新锅收件地址不能为空');
      return;
    }
    if (newPotDeliverReceiverPhone.trim() === '') {
      toast.warn('收件人电话不能为空');
      return;
    }
    const phoneReg = /^1[3456789]\d{9}$/;
    const telReg = /^0\d{2,3}-\d{7,8}$/;
    if (!phoneReg.test(newPotDeliverReceiverPhone) && !telReg.test(newPotDeliverReceiverPhone)) {
      toast.warn('收件人电话格式不正确');
      return;
    }
    if (newPotDeliverReceiverName.trim() === '') {
      toast.warn('收件人不能为空');
      return;
    }
    this.setState({ submitting: true });
    const response = await request(`/as/hg/open/potChangeRequests/submit?vidNumber=${localStorage.getItem('vidNumber')}`, {
      method: 'POST',
      body: {
        cookCount: localStorage.getItem('cookCount') || 0,
        kuaidiOldPotDeliverType: cargoFetch ? 0 : 1,
        kuaidiOldPotCompanyCode: cargoFetchCompanyCode,
        oldPotDeliverNo,
        newPotDeliverAddress,
        newPotDeliverReceiverName,
        newPotDeliverReceiverPhone,
        userMemo,
        newPotDeliverShopName
      }
    });
    if (response.constructor.name !== 'Error') {
      if (recentProcessingRequest) {
        toast.success("换锅申请已修改");
        localStorage.setItem('newPotDeliverAddress', newPotDeliverAddress);
        localStorage.setItem('newPotDeliverReceiverName', newPotDeliverReceiverName);
        localStorage.setItem('newPotDeliverReceiverPhone', newPotDeliverReceiverPhone);
        if (this.props.onRequestModified) {
          this.props.onRequestModified();
        }
      } else {
        toast.success("换锅申请已提交");
        localStorage.setItem('newPotDeliverAddress', newPotDeliverAddress);
        localStorage.setItem('newPotDeliverReceiverName', newPotDeliverReceiverName);
        localStorage.setItem('newPotDeliverReceiverPhone', newPotDeliverReceiverPhone);
        if (this.props.onRequestSubmitted) {
          this.props.onRequestSubmitted();
        }
      }
    }
    this.setState({ submitting: false });
  };
  
  render() {
    const {
      machine,
    } = this.props;
    const {
      submitting,
      oldPotDeliverNo,
      newPotDeliverAddress,
      newPotDeliverReceiverName,
      newPotDeliverReceiverPhone,
      userMemo,
      cargoFetch,
      cargoFetchCompanyCode,
      currentCookCount,
      recentProcessingRequest,
    } = this.state;
    const submitBtnDisable = recentProcessingRequest && (
      recentProcessingRequest.status > consts.potChangeRequestStatus.OldPotReceived
    );
    const cover = {
      color: 'white',
      border: '1px solid #ef7b24',
      borderRadius: '0.2rem',
      backgroundColor: '#ef7b24'
    }
    const nocover = {
      color: 'black',
    }
    return (
      <LoadingMask loading={submitting}>
        <div className={s.container}>
          <div style={{ height: '0.5rem' }} />
          <div className={s.videoContainer}>
            <div className={s.video}>
              <video src="https://image.chefeon.com/teach/%E6%8D%A2%E9%94%85%E6%95%99%E7%A8%8B.mp4"
                     poster={imgPoster}
                     className={s.video}
                     controls={true}
              />
            </div>
          </div>
          <div style={{ height: '0.5rem' }} />
          {!recentProcessingRequest &&
            <div className={s.block}>
              换锅完成后请将换下的旧锅装回原包装（双层纸箱）
              {cargoFetch ? '等待快递员上门取件' : '自行前往快递站点邮寄'}
              <span
                onClick={() => {
                  if (!cargoFetch) {
                    this.setState({
                      oldPotDeliverNo: ''
                    });
                  }
                  this.setState({
                    cargoFetch: true
                  });
                }}
              >
                <img className={s.textImg} src={cargoFetch ? imgChecked : imgNoChecked} alt="" />
                上门取件
              </span>
              <span
                onClick={() => {
                  this.setState({
                    cargoFetch: false
                  });
                }}
              >
                <img className={s.textImg} src={cargoFetch ? imgNoChecked : imgChecked } alt="" />
                自行寄回
              </span>
            </div>
          }
          {!recentProcessingRequest && cargoFetch &&
            <Fragment>
              <div className={s.block}>
                默认是顺丰，可以选择其他快递，运费自付呦
              </div>
              <div className={s.spaceAroundBlock}>
                <div
                  className={s.kuaidi}
                  style={cargoFetchCompanyCode === 'shunfeng' ? cover : nocover}
                  onClick={() => {
                    this.setState({
                      cargoFetchCompanyCode: 'shunfeng'
                    })
                  }}
                >
                  顺丰
                </div>
                <div
                  className={s.kuaidi}
                  style={cargoFetchCompanyCode === 'zhongtong' ? cover : nocover}
                  onClick={() => {
                    this.setState({
                      cargoFetchCompanyCode: 'zhongtong'
                    })
                  }}
                >
                  中通
                </div>
                <div
                  className={s.kuaidi}
                >
                </div>
                <div
                  className={s.kuaidi}
                >
                </div>
              </div>
            </Fragment>
          }
          {recentProcessingRequest &&
              <div className={s.block}>
                <span style={{ color: 'orange' }}>您有换锅申请正在进行中，如果需要修改相关信息，请在下方修改</span>
              </div>
          }
          <hr color={'#e0e0e0'} />
          {!cargoFetch &&
            <div className={s.block}>
              {'旧锅收件地址： ' + consts.recipientAddress}
              <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      copyToClipboard(consts.recipientAddress);
                      message.info('已复制旧锅收件地址');
                    }}
                >
                  复制地址
                </a>
            </div>  
          }
          {!cargoFetch &&
            <Row className={s.inputBlock}>
              <Col span={8}>
                快递单号
              </Col>
              <Col span={16}>
                <Input
                  placeholder="请输入旧锅快递单号"
                  value={oldPotDeliverNo}
                  onChange={e => this.setState({
                    oldPotDeliverNo: e.target.value
                  })}
                />
              </Col>
            </Row>
          }
          <hr color={'#e0e0e0'} />
          {!recentProcessingRequest && currentCookCount < 400 &&
            <div className={s.block}>
              本锅一共烹饪<span style={{ color: 'orange' }}>{currentCookCount}</span>次，建议<span style={{ color: 'orange' }}>400</span>次以上更换，您可以尝试手动清洗锅，看是否粘锅
            </div>
          }
          {!recentProcessingRequest && currentCookCount >= 400 &&
              <div className={s.block}>
                本锅一共烹饪<span style={{ color: 'orange' }}>{currentCookCount}</span>次
              </div>
          }
          <Row className={s.inputBlock}>
            <Col span={8}>
              <span style={{ color: 'red'}}>*</span>机器名称
            </Col>
            <Col span={16}>
              <Input
                  readOnly={true}
                  disabled={recentProcessingRequest}
                  value={machine.machineLabel}
              />
            </Col>
          </Row>
          <hr color={'#e0e0e0'} />
          <Row className={s.inputBlock}>
            <Col span={8}>
              <span style={{ color: 'red'}}>*</span>新锅收件地址
            </Col>
            <Col span={16}>
              <Input.TextArea
                placeholder="请输入收件地址"
                value={newPotDeliverAddress}
                rows={2}
                readOnly={recentProcessingRequest}
                disabled={recentProcessingRequest}
                onChange={e => this.setState({
                  newPotDeliverAddress: e.target.value
                })}
              />
            </Col>
          </Row>
          <hr color={'#e0e0e0'} />
          <Row className={s.inputBlock}>
            <Col span={8}>
              <span style={{ color: 'red'}}>*</span>收件人
            </Col>
            <Col span={16}>
              <Input
                  placeholder="请输入收件人姓名"
                  value={newPotDeliverReceiverName}
                  readOnly={recentProcessingRequest}
                  disabled={recentProcessingRequest}
                  onChange={e => this.setState({
                    newPotDeliverReceiverName: e.target.value
                  })}
              />
            </Col>
          </Row>
          <hr color={'#e0e0e0'} />
          <Row className={s.inputBlock}>
            <Col span={8}>
              <span style={{ color: 'red'}}>*</span>收件人电话
            </Col>
            <Col span={16}>
              <Input
                  placeholder="请输入收件人电话"
                  value={newPotDeliverReceiverPhone}
                  readOnly={recentProcessingRequest}
                  disabled={recentProcessingRequest}
                  onChange={e => this.setState({
                    newPotDeliverReceiverPhone: e.target.value
                  })}
              />
            </Col>
          </Row>
          <hr color={'#e0e0e0'} />
          <Row className={s.inputBlock}>
            <Col span={8}>
              备注/留言
            </Col>
            <Col span={16}>
              <Input
                placeholder="请输入备注/留言"
                value={userMemo}
                onChange={e => this.setState({
                  userMemo: e.target.value
                })}
              />
            </Col>
          </Row>
          <hr color={'#e0e0e0'} />
          <div className={s.submitBlock}>
            <button
              className={submitBtnDisable ? s.submitBtnDisabled : s.submitBtn}
              onClick={this.handleSubmit.bind(this)}
              disabled={submitBtnDisable}
            >
              {recentProcessingRequest ? '修改申请' : '提交申请'}
            </button>
          </div>
        </div>
      </LoadingMask>
    )
  }
}

export {MGuide};
